import { Link } from "react-router-dom"
import cursor from "../assets/img/cursor.png"
import i1 from "../assets/img/1.png"
import i2 from "../assets/img/2.png"
import i3 from "../assets/img/3.png"
import i4 from "../assets/img/4.png"
import i5 from "../assets/img/5.png"
import i0006 from "../assets/img/0006.png"
import i0010 from "../assets/img/0010.png"
import i0012 from "../assets/img/0012.png"
import i0025 from "../assets/img/0025.png"
import i0014 from "../assets/img/0014.png"
import i0021 from "../assets/img/0021.png"

const Bulletin = () => {
    return (
    <div>
    <Link to="/"><img title="Home" id='menu4' src={cursor} alt='menu'></img></Link>  
    <div className='content'> 
        <p>Thesis Collection</p>
          <br></br>
          <img className='bulletin' src={i1} alt='img'></img>
          <img className='bulletin' src={i2} alt='img'></img>
          <img className='bulletin' src={i3} alt='img'></img>
          <img className='bulletin' src={i4} alt='img'></img>
          <img className='bulletin' src={i5} alt='img'></img>
          <img className='bulletin' src={i0006} alt='img'></img>
          <img className='bulletin' src={i0010} alt='img'></img>
          <img className='bulletin' src={i0014} alt='img'></img>
          <img className='bulletin' src={i0012} alt='img'></img>
          <img className='bulletin' src={i0025} alt='img'></img>
          <img className='bulletin' src={i0021} alt='img'></img>
      
    </div>
    </div>
    );

  };
  
  export default Bulletin;