import { Link } from "react-router-dom"
import cursor from "../assets/img/cursor.png"
import num1 from "../assets/img/num1.png"
import num2 from "../assets/img/num2.png"
import num3 from "../assets/img/num3.png"
import mk2 from "../assets/img/mk2.png"

// import './pages.css'

const Clo = () => {
    return (
    <div>
    <Link to="/Gallery"><img id='menu1' src={cursor} alt='menu' title="Home"></img></Link> 
    <div className='content'>
    <img className='gallery' src={num1} alt='img'></img>
    <img className='gallery' src={num2} alt='img'></img>
    <img className='gallery' src={num3} alt='img'></img>
    <img className='gallery' src={mk2} alt='img'></img>
    </div>
    </div>
    );
    
  };
  
  export default Clo;