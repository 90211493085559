import { Link } from "react-router-dom"
import cursor from "../assets/img/cursor.png"
import i164 from "../assets/img/i164.png"
import i155 from "../assets/img/i155.png"
import i289 from "../assets/img/289.png"
import i296 from "../assets/img/296.png"
import i299 from "../assets/img/299.png"
import i301 from "../assets/img/301.png"
import i154 from "../assets/img/i154.png"
import i147 from "../assets/img/i147.jpg"
// import './pages.css'

const Illustration = () => {
    return (
    <div>
    <Link to="/Gallery"><img id='menu1' src={cursor} alt='menu' title="Home"></img></Link> 
    <div className='content'>
    <img className='gallery' src={i147} alt='img'></img>
    <img className='gallery' src={i164} alt='img'></img>
    <img className='gallery' src={i155} alt='img'></img>
    <img className='gallery' src={i154} alt='img'></img>
    <img className='gallery' src={i289} alt='img'></img>
    <img className='gallery' src={i296} alt='img'></img>
    <img className='gallery' src={i299} alt='img'></img>
    <img className='gallery' src={i301} alt='img'></img>
    </div>
    </div>
    );
    
  };
  
  export default Illustration;