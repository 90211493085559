import { Link } from "react-router-dom"
import cursor from "../assets/img/cursor.png"


const Gallery = () => {
    return (
    <div>
    <Link to="/"><img id='menu1' src={cursor} alt='menu' title="Home"></img></Link> 
    <Link to="/Illustration"><p className='sub' id='illustration'>Illustration</p></Link> 
    <Link to="/Clo"><p className='sub' id='clo'>CLO3D</p></Link>     
    </div>
    );
    
  };
  
  export default Gallery;